import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useMenuButton,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import * as React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { HiSelector } from 'react-icons/hi';
import { BiBulb, BiBuoy, BiDollar, BiHelpCircle } from 'react-icons/bi';
import { useIntercom } from 'react-use-intercom';

const HelpMenuButton = () => {
  const buttonProps = useMenuButton()
  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      //bg={useColorModeValue("gray.100", "gray.700")}
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: useColorModeValue("gray.200", "gray.600") }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack flex="1" spacing="3">
        <Icon as={BiHelpCircle} fontSize="2xl" />
        <Text>Help & Support</Text>
      </HStack>
    </Flex>
  )
}

export const HelpButton = (props) => {
  const navigate = useNavigate()
  const { showNewMessages } = useIntercom()

  const sendFeatureRequestMessage = React.useCallback(
    () => showNewMessages('My feature request is: '),
    [showNewMessages],
  );

  const sendConsultationMessage = React.useCallback(
    () => showNewMessages('Schedule an onboarding call'),
    [showNewMessages],
  );

  const openEmailClient = (subject) => {
    window.open(`mailto:support@botdisco.com?subject=${subject}`, '_blank')
  }
  return (
    <Menu>
      <HelpMenuButton />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue('gray.600', 'gray.200')}
        px="3"
        maxH={'50vh'}
        overflow='auto'
      >
        <MenuItem
          rounded="md"
          as={Link}
          isExternal
          style={{ textDecoration: 'none' }}
          href="https://www.botdisco.com/tutorials"
          icon={<Icon as={BiBuoy} fontSize="xl" />}
        >
          Help center
        </MenuItem>
        <MenuItem
          rounded="md"
          icon={<Icon as={BiBulb} fontSize="xl" />}
          onClick={() => { openEmailClient('Feature Request') }}
        >
          Request feature
        </MenuItem>
        <MenuItem
          rounded="md"
          icon={<Icon as={BiDollar} fontSize="xl" />}
          onClick={() => { openEmailClient('Request for Consultation') }}
        >
          Hire a bot builder
        </MenuItem>
        <HStack justifyContent={'space-between'} spacing={1} pt={2}>
          <Button
            onClick={() => { window.open('https://www.botdisco.com/terms', '_blank') }}
            w={'100%'}
            variant={'outline'}
            size={'xs'}>Terms Of Use</Button>
          <Button
            onClick={() => { window.open('https://www.botdisco.com/privacy', '_blank') }}
            w={'100%'}
            variant={'outline'}
            size={'xs'}>Privacy Policy</Button>
        </HStack>

      </MenuList>
    </Menu >
  )
}
