import { Box, Circle, Flex, Stack, useColorModeValue as mode } from "@chakra-ui/react"
import {
  BiBot,
  BiWrench,
  BiSlider,
  BiMessageError,
  BiCreditCard,
  BiGroup,
  BiData,
  BiExtension,
  BiBuoy,
  BiQuestionMark
} from 'react-icons/bi'
import { useLocation, matchPath } from 'react-router-dom';
import { AccountSwitcher } from "./AccountSwitcher"
import BotDiscoLabel from "./BotDiscoLabel";
import { NavItem } from "./NavItem"
import { ProfileButton } from "./ProfileButton";
import Div100vh from 'react-div-100vh'
import { useContext } from "react";
import { LogsContext } from "../../context/LogsContextComponent";
import { GuildContext } from '../../context/GuildContextComponent'
import { HelpButton } from "./HelpButton";

const DashboardSidebar = (props) => {
  const { showSidebarBuilder } = props
  const location = useLocation();
  const { hasUnreadLogs } = useContext(LogsContext)
  const { qnaBotOnboarded } = useContext(GuildContext)
  console.log(qnaBotOnboarded)

  const pathMatch = (href) => {
    return href ? !!matchPath({
      path: "/dashboard/:guildId/" + href,
      end: false
    }, location.pathname) : false
  };

  return (
    <Box
      w="64"
      bg={mode("white", "gray.900")}
      fontSize="sm"
      borderRight='1px'
      borderColor='gray.300'
      minH="100vh"
      maxH="100vh"
    >
      <Div100vh>
        <Flex h="full" direction="column" px="4" py="4" >
          {showSidebarBuilder ? <AccountSwitcher /> : <BotDiscoLabel />}
          <Stack spacing="8" flex="1" overflow="auto" pt="8">
            {showSidebarBuilder &&
              <Stack spacing="1">
                <NavItem active={pathMatch('builder')} startElement={<BiWrench />} label="Builder" href={'builder'} />
                {/* <NavItem active={pathMatch('settings')} startElement={<BiSlider />} label="Bot Settings" href={'settings'} /> */}
                <NavItem active={pathMatch('members')} startElement={<BiGroup />} label="Members" href={'members'} />
                <NavItem active={pathMatch('member-fields')} startElement={<BiData />} label="Member Fields" href={'member-fields'} />
                <NavItem active={pathMatch('custom-bot')} startElement={<BiBot />} label="Custom Bot" href={'custom-bot'} />
                <NavItem active={pathMatch('qna-bot')} startElement={<BiQuestionMark />} label="FAQ Bot" href={qnaBotOnboarded?'qna-bot-add-remove-faqs':'qna-bot-onboarding'} />
                <NavItem active={pathMatch('examples')} startElement={<BiExtension />} label="Examples" href={'examples'} />
                <NavItem
                  active={pathMatch('logs')}
                  startElement={<BiMessageError />}
                  endElement={hasUnreadLogs && <Circle size="2" bg="red.400" />}
                  label="Error Logs"
                  href={'logs'}
                />

                {/* <NavItem active={pathMatch('logs')} startElement={<BiCommentError />} label="Error Logs" href={'logs'} /> */}
              </Stack>
            }

            {/* <NavGroup label="Create custom bot">
              <NavItem startElement={<AddIcon />} label="Create custom bot" />
            </NavGroup> */}
          </Stack>
          <Box >
            <Stack spacing="1">
              {showSidebarBuilder && <NavItem active={pathMatch('billing')} subtle startElement={<BiCreditCard />} label="Billing" href={'billing'} />}
              <ProfileButton />
              {/* <NavItem
                subtle
                startElement={<BiBuoy />}
                label="Help & Support"
                endElement={<Circle size="2" bg="blue.400" />}
              /> */}
              <HelpButton />

            </Stack>
          </Box>
        </Flex>

      </Div100vh>
    </Box>

  )
}
export default DashboardSidebar