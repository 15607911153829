import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import LoadingBox from "../components/LoadingBox";

export const ProtectedRoute = ({
  component,
}) => {

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingBox />,
  });

  console.log('protected route updated');

  return <Component />;
};