import configJson from './auth_config.json';

export function getConfig() {
    let audience = '';
    let apiOrigin = '';
    let domain = '';
    let clientId = '';

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        apiOrigin = configJson.devApiOrigin;
        audience = configJson.devAudience;
        domain = configJson.devDomain;
        clientId = configJson.devClientId;
    } else if (process.env.REACT_APP_STAGE === 'staging') {
        apiOrigin = configJson.stageApiOrigin;
        audience = configJson.stageAudience;
        domain = configJson.stageDomain;
        clientId = configJson.stageClientId;
    } else {
        apiOrigin = configJson.prodApiOrigin;
        audience = configJson.prodAudience;
        domain = configJson.prodDomain;
        clientId = configJson.prodClientId;
    }

    return {
        domain: domain,
        apiOrigin: apiOrigin,
        clientId: clientId,
        ...(audience ? { audience } : null),
    };
}