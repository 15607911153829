import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from '../config';

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const { domain, clientId, audience } = getConfig();

  const onRedirectCallback = (appState) => {
    // TODO pass along the doSync parameter here!
    console.log(appState?.returnTo);
    console.log(window.location.pathname);
    if (appState?.returnTo && appState.returnTo !== '/') {
      navigate(`${appState?.returnTo}?doSync=true`)
    } else {
      navigate(`${window.location.pathname}?doSync=true`);
    }
  };


  return (
    <Auth0Provider
      domain={domain}
      connection={'Discord'}
      clientId={clientId}
      redirectUri={window.location.origin + '/dashboard?doSync=true'}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
